import { Refresh } from "@mui/icons-material"
import { Button, Stack } from "@mui/material"
import { Flex, Span, Text } from "components/common"
import { APP_NAME } from "env"
import { useState } from "react"

type Props = {
  onRefetch?: () => void
  onSearchChange?: (values: TourRowParams) => void
  tripCount?: number
}

const sortOptions = [
  { label: "Ngày khởi hành gần nhất", value: "startDate_ASC" },
  { label: "Giá từ thấp đến cao", value: "adultsPriceSales_ASC" },
  { label: "Giá từ cao đến thấp", value: "adultsPriceSales_DESC" },
]

const TourSearchHeading = ({ onRefetch, onSearchChange, tripCount }: Props) => {
  const [selectedType, setSelectedType] = useState(sortOptions[0].value)

  return (
    <Flex className="flex-col items-center justify-between md:flex-row md:items-end">
      <Stack alignItems="center" className="flex-row gap-2 md:flex-col md:gap-0">
        <Text className="text-2xl font-extrabold" color="secondary">
          Tour Du Lịch
        </Text>
        <Text className="text-3xl font-extrabold" color="secondary">
          {APP_NAME}
        </Text>
      </Stack>

      <Stack alignItems="flex-end" spacing={1}>
        <Flex alignItems="center" gap={1}>
          <Text className="text-right font-bold text-gray-600">
            Chúng tôi tìm thấy <Span className="text-xl text-secondary-main">{tripCount}</Span> chương trình tour cho
            Quý khách
          </Text>
          <Refresh className="cursor-pointer text-primary-main hover:brightness-125" onClick={onRefetch} />
        </Flex>
        <Flex className="gap-2">
          {sortOptions.map((option) => {
            const [sortBy, sortDirection] = option.value.split("_")
            const isSelected = option.value === selectedType
            return (
              <Button
                key={option.value}
                onClick={() => {
                  setSelectedType(option.value)
                  onSearchChange?.({ sortBy, sortDirection: sortDirection as "asc" })
                }}
                size="small"
                variant={isSelected ? "contained" : "outlined"}
              >
                {option.label}
              </Button>
            )
          })}
        </Flex>
      </Stack>
    </Flex>
  )
}

export default TourSearchHeading
