import {
  AccessTimeOutlined,
  ConfirmationNumberOutlined,
  FlightTakeoffOutlined,
  NightShelterOutlined,
  PlaceOutlined,
  WeekendOutlined,
} from "@mui/icons-material"
import { Box, Button, Card, CardMedia, Stack } from "@mui/material"
import { Flex, FlexColumn, Span, Text } from "components/common"
import { Link } from "react-router-dom"
import { privateRoute } from "routes"

type Props = {
  tour: TourRow
}

const vehiclesName = {
  AIRPLANE: "Máy bay",
  CAR: "Xe ô tô",
  CUSTOM: "Tự chọn",
  HIGH_SPEED_TRAIN: "Tàu cao tốc",
  TRAIN: "Tàu hỏa",
}

const defaultDepartPoint = "TP. Hồ Chí Minh"

const TourCardItem = ({ tour }: Props) => {
  return (
    <Card className="p-3 shadow-md">
      <FlexColumn className="gap-4 md:flex-row">
        <CardMedia
          alt={tour.name}
          className="m-auto h-full max-w-[400px] rounded-lg object-cover md:w-1/3"
          component="img"
          image={
            tour?.image?.path ||
            "https://airdata-cms-dev.sgp1.cdn.digitaloceanspaces.com/banner/background-tour.webp" ||
            "https://via.placeholder.com/400"
          }
        />

        <Stack flex={1} spacing={1}>
          <Text variant="h6">{tour.name}</Text>

          <FlexColumn className="grid-cols-2 gap-2 md:grid">
            <Flex className="items-center">
              <ConfirmationNumberOutlined className="mr-1" color="success" />
              <Span className="mr-1">Mã:</Span>
              <Span className="font-bold text-info">{tour.tourCode}</Span>
            </Flex>
            <Flex className="items-center">
              <PlaceOutlined className="mr-1" color="success" />
              <Span className="mr-1">Khởi hành:</Span>
              <Span className="font-bold text-info">{defaultDepartPoint}</Span>
            </Flex>
            <Flex className="items-center">
              <NightShelterOutlined className="mr-1" color="success" />
              <Span className="mr-1">Khách sạn:</Span>
              <Span className="font-bold text-info">{tour.levelNam}</Span>
            </Flex>
            <Flex className="items-center">
              <AccessTimeOutlined className="mr-1" color="success" />
              <Span className="mr-1">Thời gian:</Span>
              <Span className="font-bold text-info">
                {tour.totalDay}N{tour.totalNight}Đ
              </Span>
            </Flex>
            <Flex className="items-center">
              <FlightTakeoffOutlined className="mr-1" color="success" />
              <Span className="mr-1">Phương tiện:</Span>
              <Span className="font-bold text-info">
                {vehiclesName[tour.vehiclesGo]}
                {tour.vehiclesBack && tour.vehiclesBack !== tour.vehiclesGo
                  ? ` - ${vehiclesName[tour.vehiclesBack]}`
                  : ""}
              </Span>
            </Flex>
            <Flex className="items-center">
              <WeekendOutlined className="mr-1" color="success" />
              <Span className="mr-1">Số chỗ:</Span>
              <Span className="font-bold text-success">{tour.numberTicketsAvailable}</Span>
            </Flex>

            <Stack className="col-span-2">
              {tour?.itineraries?.length &&
                tour.itineraries.map((itinerary: TourBookingItinerary[], index) => (
                  <Box className="md:ml-2" key={index}>
                    {itinerary.map((segment: TourBookingItinerary, idx) => (
                      <Flex className="gap-2 text-xs font-bold sm:text-base" key={idx}>
                        <Span className="text-warning">
                          {segment.airline}
                          {segment.flightNumber}
                        </Span>
                        <Span className="text-nowrap">
                          {segment.departure}-{segment.destination}
                          {" /"}
                        </Span>
                        <Span className="text-warning">
                          {segment.departureTime}-{segment.arrivalTime}
                        </Span>
                        <Span className="">{segment.departureDate}</Span>
                      </Flex>
                    ))}
                  </Box>
                ))}
            </Stack>

            <Stack>
              <Flex className="items-center">
                <Span className="mr-1">Giá chỉ từ:</Span>
                {tour.adultsPriceSales < tour.adultsPrice && (
                  <Span className="font-bold line-through">{tour.adultsPrice.toLocaleString()}</Span>
                )}
              </Flex>
              <Span className="text-xl font-extrabold text-error">{tour.adultsPriceSales.toLocaleString()} VNĐ</Span>
            </Stack>

            <Flex className="items-end justify-between">
              <Stack>
                <Text className="font-bold">Hoa hồng</Text>
                <Text className="font-bold text-success">{tour.adultsCommission.toLocaleString()} VNĐ</Text>
              </Stack>

              <Link to={privateRoute.tourSearchDetail.url(tour.priceId)}>
                <Button color="success" size="small" variant="contained">
                  Xem chi tiết
                </Button>
              </Link>
            </Flex>
          </FlexColumn>
        </Stack>
      </FlexColumn>
    </Card>
  )
}

export default TourCardItem
