import { LoadingButton } from "@mui/lab"
import { Button, CircularProgress, Container, Stack } from "@mui/material"
import { useMutation, useQuery } from "@tanstack/react-query"
import { Center, Flex } from "components/common"
import { enqueueSnackbar } from "notistack"
import { FormProvider, useForm } from "react-hook-form"
import { useNavigate, useParams } from "react-router-dom"
import { privateRoute } from "routes"
import { queryClient, tourService } from "services"
import { TourDetailFlightsAndPrices } from "views/TourDetail/components"

import { TourCreateContactInfoCard } from "./components"

const TourCreate = () => {
  const navigate = useNavigate()
  const { priceId } = useParams()

  const { data: tour } = useQuery({
    queryFn: () => tourService.getTourRow({ id: priceId! }),
    queryKey: ["tourService.getTourRow", priceId],
  })

  const form = useForm<TourBookingCreateBody>({
    defaultValues: {
      address: "",
      contactName: "",
      contactPhone: "",
      email: "",
      numberAdults: 1,
      numberChildren1: 0,
      numberChildren2: 0,
      numberInfant: 0,
      priceId,
      sendMailCustomer: false,
      totalSingleRoom: 0,
    },
  })

  const {
    formState: { isSubmitting },
    handleSubmit,
  } = form

  const createTourMutation = useMutation({
    mutationFn: tourService.createBooking,
    onSuccess: () => {
      enqueueSnackbar("Đặt Tour thành công")
      navigate(privateRoute.tourList.path)
      queryClient.invalidateQueries({ queryKey: ["tourService.fetchBookings"] })
    },
  })

  const onSubmit = async (formValues: TourBookingCreateBody) =>
    await createTourMutation.mutateAsync({ ...formValues, type: tour?.type })

  if (!tour)
    return (
      <Center padding={10}>
        <CircularProgress />
      </Center>
    )

  return (
    <Container className="space-y-6 max-sm:px-0">
      <TourDetailFlightsAndPrices tour={tour} />

      <FormProvider {...form}>
        <Stack spacing={2}>
          <TourCreateContactInfoCard tour={tour} />

          <Flex className="justify-center gap-4 sm:gap-6">
            <Button
              color="inherit"
              onClick={() => {
                navigate(-1)
              }}
            >
              Quay lại
            </Button>
            <LoadingButton
              className="px-12"
              loading={isSubmitting}
              onClick={handleSubmit(onSubmit)}
              variant="contained"
            >
              Xác nhận đặt Tour
            </LoadingButton>
          </Flex>
        </Stack>
      </FormProvider>
    </Container>
  )
}

export default TourCreate
