import { Chip } from "@mui/material"

type StatusChipProps = {
  status: TourBookingStatus
}

const TourStatusChip = ({ status }: StatusChipProps) => {
  let color: "default" | "error" | "info" | "primary" | "secondary" | "success" | "warning"
  let label: string

  switch (status) {
    case "CANCELLED":
      color = "default"
      label = "Đã huỷ"
      break
    case "PAID":
      color = "success"
      label = "Đã thanh toán"
      break
    case "EXPIRED":
      color = "default"
      label = "Đã hết hạn"
      break
    case "RESERVED":
      color = "info"
      label = "Giữ chỗ"
      break
    case "REQUEST_DEPOSIT":
      color = "warning"
      label = "Yêu cầu đặt cọc"
      break
    case "REQUEST_UPDATE":
      color = "warning"
      label = "Yêu cầu chỉnh sửa"
      break
    case "REQUEST_PAYMENT":
      color = "primary"
      label = "Yêu cầu thanh toán"
      break
    case "CANCELLATION_REQUESTED":
      color = "error"
      label = "Yêu cầu huỷ"
      break
    case "DEPOSITED":
      color = "success"
      label = " Đã đặt cọc"
      break
    default:
      color = "default"
      label = "-"
      break
  }

  return <Chip className="flex-grow uppercase" color={color} label={label} />
}

export default TourStatusChip
