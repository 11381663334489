import { AccessAlarmOutlined } from "@mui/icons-material"
import { IconButton, Tooltip } from "@mui/material"
import { DateTime } from "luxon"

type Props = {
  fontSize?: 16 | number
  startDate: LuxonDate
  status: TourBookingStatus
}

const TourIconWarningPayment = ({ fontSize, startDate, status }: Props) => {
  const isWarningBooking =
    DateTime.local().plus({ days: 8 }) > DateTime.fromISO(startDate, { zone: "utc" }) && ["DEPOSITED"].includes(status)
  if (!isWarningBooking) return
  return (
    <Tooltip placement="top" title="Tour sắp đến hạn thanh toán !">
      <IconButton className="ml-2" color="error">
        <AccessAlarmOutlined sx={{ fontSize: fontSize }} />
      </IconButton>
    </Tooltip>
  )
}

export default TourIconWarningPayment
