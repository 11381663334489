import { ExpandLess, ExpandMore } from "@mui/icons-material"
import { Button, Collapse, Paper, Stack } from "@mui/material"
import { Flex, FlexColumn, Span, Text } from "components/common"
import { useEffect, useId } from "react"
import { Link } from "react-router-dom"
import { useToggle } from "react-use"
import { privateRoute } from "routes"

type Props = {
  tour: TourRow
}

const TourDetailRelated = ({ tour }: Props) => {
  const [isOpen, toggleCollapse] = useToggle(true)
  const idContainer = useId().replace(/^:/, "").replace(/:$/, "")
  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        document.getElementById(idContainer)?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        })
      }, 200)
    }
  }, [isOpen, idContainer])
  return (
    <Paper className="p-3 shadow-md" variant="outlined">
      <Flex className="cursor-pointer items-center justify-between" onClick={toggleCollapse}>
        <Flex className="min-h-10 items-center">
          <Text color="primary" variant="h5">
            Các Tour Du lịch khác
          </Text>
        </Flex>
        <Button variant="text">{isOpen ? <ExpandLess /> : <ExpandMore />}</Button>
      </Flex>

      <Collapse in={isOpen}>
        <Stack mt={1}>
          <FlexColumn className="grid-cols-3 gap-3 md:grid">
            {tour.anotherTours.map((tour) => {
              return (
                <FlexColumn
                  className="h-[320px] justify-end rounded-lg p-3"
                  key={tour.priceId}
                  style={{
                    backgroundImage: `url(${
                      tour?.image?.path ||
                      "https://airdata-cms-dev.sgp1.cdn.digitaloceanspaces.com/banner/background-tour.webp" ||
                      "https://via.placeholder.com/400"
                    })`,
                  }}
                >
                  <Stack className="rounded-md bg-white/75 p-3" spacing={1}>
                    <Text variant="h5">{tour.name}</Text>
                    <Text className="font-bold">{tour.tourCode}</Text>
                    <Flex className="justify-between">
                      <Text className="font-bold">
                        Giá: <Span className="text-xl text-error">{tour.adultsPriceSales.toLocaleString()}</Span>
                      </Text>
                      <Link to={privateRoute.tourSearchDetail.url(tour.priceId)}>
                        <Text className="font-bold" color="primary">
                          Chi tiết
                        </Text>
                      </Link>
                    </Flex>
                  </Stack>
                </FlexColumn>
              )
            })}
          </FlexColumn>
        </Stack>
      </Collapse>
    </Paper>
  )
}

export default TourDetailRelated
