import { Add, Remove } from "@mui/icons-material"
import { Box, Card, CardContent, CardHeader, IconButton, Stack, TextField } from "@mui/material"
import { Flex, FlexColumn, Span, Text } from "components/common"
import { Fragment } from "react"
import { Controller, useFormContext } from "react-hook-form"
import { formatDuplicateTrim, formatRemoveAccents } from "utils/common"

type Props = {
  tour: TourRow
}

const TourCreateContactInfoCard = ({ tour }: Props) => {
  const { control, watch } = useFormContext<TourBookingCreateBody>()
  const { numberAdults, numberChildren1, numberChildren2, numberInfant, totalSingleRoom } = watch()
  const totalPrice =
    tour.adultsPriceSales * numberAdults +
    tour.childrenHasBedPriceSales * numberChildren1 +
    tour.childrenNoBedPriceSales * numberChildren2 +
    tour.infantPriceSales * numberInfant +
    tour.singleRoomPrice * totalSingleRoom

  const totalCommission =
    tour.adultsCommission * numberAdults +
    tour.childrenHasBedCommission * numberChildren1 +
    tour.childrenNoBedCommission * numberChildren2 +
    tour.infantCommission * numberInfant

  return (
    <Stack spacing={3}>
      <Card>
        <CardHeader title="Thông tin liên lạc" />
        <CardContent>
          <FlexColumn className="grid-cols-2 gap-8 md:grid">
            <Controller
              control={control}
              name="contactName"
              render={({ field, fieldState: { error } }) => (
                <TextField
                  fullWidth
                  label="Họ và tên"
                  placeholder="Nhập họ tên"
                  required
                  {...field}
                  error={!!error}
                  helperText={error?.message}
                  onBlur={(event) => {
                    const formatted = formatRemoveAccents(event.target.value)
                    field.onChange(formatDuplicateTrim(formatted))
                  }}
                  onChange={(event) => {
                    const formatted = event.target.value.toUpperCase()
                    field.onChange(formatted)
                  }}
                />
              )}
              rules={{ required: "Vui lòng nhập họ tên" }}
            />
            <Controller
              control={control}
              name="contactPhone"
              render={({ field, fieldState: { error } }) => (
                <TextField
                  fullWidth
                  label="Điện thoại"
                  placeholder="Nhập số điện thoại"
                  required
                  {...field}
                  error={!!error}
                  helperText={error?.message}
                  onChange={(event) => {
                    const formatted = event.target.value.trim()
                    field.onChange(formatted)
                  }}
                />
              )}
              rules={{
                pattern: {
                  message: "Số điện thoại không hợp lệ",
                  value: /^(\+)?(?:[0-9] ?){6,19}[0-9]$/,
                },
                required: "Vui lòng nhập số điện thoại",
              }}
            />
            <Controller
              control={control}
              name="email"
              render={({ field, fieldState: { error } }) => (
                <TextField
                  fullWidth
                  label="Email"
                  placeholder="Nhập email"
                  required
                  {...field}
                  error={!!error}
                  helperText={error?.message}
                  onChange={(event) => {
                    const formatted = event.target.value.trim()
                    field.onChange(formatted)
                  }}
                />
              )}
              rules={{
                pattern: {
                  message: "Email không hợp lệ",
                  value: /^[a-zA-Z0-9._]+@[a-zA-Z0-9]+(?:\.[a-zA-Z0-9]+)*\.[a-zA-Z]{2,}$/,
                },
                required: "Vui lòng nhập email",
              }}
            />
            <Controller
              control={control}
              name="address"
              render={({ field, fieldState: { error } }) => (
                <TextField
                  fullWidth
                  label="Địa chỉ"
                  placeholder="Nhập địa chỉ"
                  {...field}
                  error={!!error}
                  helperText={error?.message}
                  onBlur={(event) => {
                    const formatted = formatDuplicateTrim(event.target.value)
                    field.onChange(formatted)
                  }}
                />
              )}
            />
          </FlexColumn>
        </CardContent>
      </Card>

      <Card>
        <CardHeader title="Chi tiết giá" />
        <CardContent>
          <Box className="overflow-auto">
            <FlexColumn className="grid min-w-[550px] grid-cols-4 gap-3 text-nowrap">
              <Text className="bg-gray-100 p-2 text-sm font-bold">LOẠI KHÁCH</Text>
              <Text className="bg-gray-100 p-2 text-right text-sm font-bold">GIÁ TOUR / NGƯỜI</Text>
              <Text className="bg-gray-100 p-2 text-center text-sm font-bold">SỐ LƯỢNG</Text>
              <Text className="bg-gray-100 p-2 text-right text-sm font-bold">TỔNG TIỀN</Text>
              {[
                {
                  commission: tour.adultsCommission,
                  description: `(Từ ${tour.agesAdults + 1} tuổi trở lên)`,
                  enabled: tour.agesAdults > 0,
                  label: "Người lớn",
                  minValue: 1,
                  name: "numberAdults",
                  unitPrice: tour.adultsPriceSales,
                },
                {
                  commission: tour.childrenHasBedCommission,
                  description: `(Từ ${tour.agesChildren1From}-${tour.agesChildren1To} tuổi)`,
                  enabled: tour.agesChildren1From > 0,
                  label: "Trẻ em 1",
                  minValue: 0,
                  name: "numberChildren1",
                  unitPrice: tour.childrenHasBedPriceSales,
                },
                {
                  commission: tour.childrenNoBedCommission,
                  description: `(Từ ${tour.agesChildren2From || tour.agesChildren1From}-${
                    tour.agesChildren2To || tour.agesChildren1To
                  } tuổi)`,
                  enabled: (tour.agesChildren2From || tour.agesChildren1From) > 0,
                  label: "Trẻ em 2 (ngủ ghép)",
                  minValue: 0,
                  name: "numberChildren2",
                  unitPrice: tour.childrenNoBedPriceSales,
                },
                {
                  commission: tour.infantCommission,
                  description: `(Dưới ${tour.agesInfant} tuổi)`,
                  enabled: tour.agesInfant > 0,
                  label: "Em bé",
                  minValue: 0,
                  name: "numberInfant",
                  unitPrice: tour.infantPriceSales,
                },
                {
                  commission: 0,
                  description: `(Tuỳ chọn)`,
                  enabled: true,
                  highlight: true,
                  label: "Thêm phòng đơn",
                  minValue: 0,
                  name: "totalSingleRoom",
                  unitPrice: tour.singleRoomPrice,
                },
              ]
                .filter((field) => field.enabled)
                .map((field, index) => {
                  return (
                    <Controller
                      control={control}
                      key={index}
                      name={field.name as "numberAdults"}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <Fragment>
                            <Stack px={1}>
                              <Text className={`font-bold ${field.highlight ? "text-info" : ""}`}>{field.label}</Text>
                              <Text className="text-sm font-bold text-gray-600">{field.description}</Text>
                            </Stack>
                            <Stack className="items-end justify-center" px={1}>
                              <Text className={`font-bold ${field.highlight ? "text-info" : ""}`}>
                                {field.unitPrice.toLocaleString()}
                              </Text>
                              <Text className="text-sm italic">
                                Hoa hồng:{" "}
                                <Span className="font-bold text-success">{field.commission.toLocaleString()}</Span>
                              </Text>
                            </Stack>
                            <Flex className="items-center justify-center gap-2" px={1}>
                              <IconButton
                                color="error"
                                disabled={value <= field.minValue}
                                onClick={() => onChange(value - 1)}
                              >
                                <Remove />
                              </IconButton>
                              <Text className="font-bold">{value}</Text>
                              <IconButton color="info" onClick={() => onChange(value + 1)}>
                                <Add />
                              </IconButton>
                            </Flex>
                            <Flex className="items-center justify-end" px={1}>
                              <Text className={`font-bold ${field.highlight ? "text-info" : ""}`}>
                                {(field.unitPrice * value).toLocaleString()}
                              </Text>
                            </Flex>
                          </Fragment>
                        )
                      }}
                    />
                  )
                })}
            </FlexColumn>
          </Box>

          <Flex className="mt-4 items-center justify-end gap-6" px={1}>
            <Stack className="items-end">
              <Text className="text-lg font-bold text-gray-600">
                Tổng: <Span className="text-xl text-error">{totalPrice.toLocaleString()}</Span>
              </Text>
              <Text className="text-base font-bold italic text-gray-600">
                Hoa hồng: <Span className="text-md text-success">{totalCommission.toLocaleString()}</Span>
              </Text>
            </Stack>
          </Flex>
        </CardContent>
      </Card>
    </Stack>
  )
}

export default TourCreateContactInfoCard
