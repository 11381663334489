import { Chip } from "@mui/material"
import { DateTime } from "luxon"
import { useState } from "react"
import { useInterval } from "react-use"

interface Props {
  bookingExpirationDate: string
  onExpired: () => void
}

const CountdownTimer = ({ bookingExpirationDate, onExpired }: Props) => {
  const expirationTime = DateTime.fromISO(bookingExpirationDate, { zone: "utc" }).toLocal()
  const [remainingTime, setRemainingTime] = useState<string>("")

  useInterval(() => {
    const now = DateTime.now()

    if (expirationTime <= now) {
      setRemainingTime("Đã hết hạn")
      onExpired()
      return
    }

    const diff = expirationTime.diff(now, ["days", "hours", "minutes", "seconds"])

    const days = Math.floor(diff.days)
    const hours = diff.hours
    const minutes = diff.minutes
    const seconds = Math.round(diff.seconds)

    if (seconds < 0 && minutes < 1) {
      onExpired()
    }

    setRemainingTime(
      `${days ? `${days} ngày ` : ""}${hours ? `${hours} giờ ` : ""}${
        minutes ? `${minutes} phút ` : " "
      }${seconds} giây`,
    )
  }, 1000)

  return <Chip color="error" label={`Còn ${remainingTime}`} size="small"></Chip>
}

export default CountdownTimer
