import { Construction, DeleteOutlined, Edit } from "@mui/icons-material"
import { Box, Button, Typography } from "@mui/material"
import { DataGrid, GridColDef } from "@mui/x-data-grid"
import { keepPreviousData, useMutation, useQuery } from "@tanstack/react-query"
import { Flex, FlexColumn, Span, Text } from "components/common"
import { useConfirmationDialog, useSearch } from "hooks"
import { P } from "models/Permissions"
import { enqueueSnackbar } from "notistack"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { profileSelector } from "reducers/profileSlice"
import { privateRoute } from "routes"
import { promotionTourService, queryClient, tourService } from "services"
import { formatCurrency, formatDate } from "utils/common"
import { PromotionTourSearch } from "views/PromotionTour"

import { customerTypeOptions, tourTypeOptions } from "./utils"

const PromotionTourList = () => {
  const { openConfirm } = useConfirmationDialog()
  const { dataSearch, onSearchChange } = useSearch()
  const { permissions = [] } = useSelector(profileSelector)

  const { data } = useQuery({
    placeholderData: keepPreviousData,
    queryFn: () => promotionTourService.fetchPromotionTours(dataSearch),
    queryKey: ["promotionTourService.fetchPromotionTours", dataSearch],
  })
  const { items = [], page = 1, size = 10, sortBy, sortDirection, total = 0 } = data ?? {}

  const { data: categoryQuery } = useQuery({
    queryFn: () => tourService.getCategory(),
    queryKey: ["tourService.getCategory"],
  })

  const deletePromotionTourMutation = useMutation({
    mutationFn: promotionTourService.deletePromotionTour,
    onSuccess: () => {
      enqueueSnackbar("Xoá cài đặt phí thành công")
      queryClient.invalidateQueries({ queryKey: ["promotionTourService.fetchPromotionTours"] })
      queryClient.invalidateQueries({ queryKey: ["airportService.fetchAirportsAndFares"] })
    },
  })

  return (
    <Box>
      <FlexColumn className="items-center justify-between gap-4 sm:flex-row">
        <Typography variant="h4">Cài đặt Promotion Du lịch</Typography>
        {permissions.includes(P.PROMOTION_TOUR_CREATE) && (
          <Link to={privateRoute.settingsPromotionsTourCreate.path}>
            <Button startIcon={<Construction />}>Tạo Promotion</Button>
          </Link>
        )}
      </FlexColumn>
      <PromotionTourSearch marginTop={3} onSearchChange={onSearchChange}></PromotionTourSearch>

      <DataGrid
        columns={([] as GridColDef<PromotionTour>[])
          .concat([
            {
              field: "code",
              headerName: "Mã",
              minWidth: 100,
              renderCell: ({ value }) => <Text fontFamily="monospace">{value}</Text>,
              sortable: false,
            },
            {
              field: "name",
              flex: 1,
              headerName: "Tên chương trình",
              minWidth: 150,
              sortable: false,
            },
            {
              field: "tourType",
              flex: 1,
              headerName: "Khu vực",
              minWidth: 140,
              renderCell: ({ value }) => tourTypeOptions.find((option) => option.id === value)?.label,
              sortable: false,
            },
            {
              field: "categoryId",
              flex: 1,
              headerName: "Điểm đến",
              minWidth: 170,
              renderCell: ({ value: categoryId }) => categoryQuery?.items.find((item) => item.id === categoryId)?.name,
              sortable: false,
            },
            {
              field: "applicableCustomerTypes",
              flex: 1,
              headerName: "Giảm giá",
              minWidth: 200,
              renderCell: ({ row }) => (
                <FlexColumn className="gap-1">
                  {row.applicableCustomerTypes
                    ?.filter((applicable) => applicable.discountAmount > 0 || applicable.discountPercent > 0)
                    .map((applicable, index) => {
                      const applicableCustomer = customerTypeOptions.find((option) => option.id === applicable.type)
                      const discountAmount = formatCurrency(applicable.discountAmount)
                      const discountPercent = `${applicable.discountPercent} %`
                      return (
                        <div key={index}>
                          <Span className="text-gray-600">{applicableCustomer?.label}: </Span>
                          <Span className="font-bold">{discountAmount || discountPercent}</Span>
                        </div>
                      )
                    })}
                </FlexColumn>
              ),
              sortable: false,
            },
            {
              field: "startTime",
              headerName: "Thời gian áp dụng",
              minWidth: 220,
              renderCell: ({ row }) => `${formatDate(row.startTime)}-${formatDate(row.endTime)}`,
              sortable: false,
            },
          ])
          .concat([
            {
              align: "center",
              field: "actions",
              headerName: "",
              renderCell: ({ row: item }) => {
                return (
                  <Flex className="flex-nowrap gap-2">
                    {permissions.includes(P.PROMOTION_TOUR_UPDATE) && (
                      <Link to={privateRoute.settingsPromotionsTourEdit.url(item)}>
                        <Button size="small">
                          <Edit />
                        </Button>
                      </Link>
                    )}
                    {permissions.includes(P.PROMOTION_TOUR_DELETE) && (
                      <Button
                        color="error"
                        onClick={() => {
                          openConfirm({
                            onConfirmAsync: () => deletePromotionTourMutation.mutateAsync({ id: item.id }),
                          })
                        }}
                        size="small"
                      >
                        <DeleteOutlined />
                      </Button>
                    )}
                  </Flex>
                )
              },
              sortable: false,
              width: 128 + 0,
            },
          ])}
        onPaginationModelChange={(model) => {
          const { page, pageSize } = model
          onSearchChange({ page: page + 1, size: pageSize })
        }}
        onSortModelChange={(model) => {
          const { field, sort } = model[0] ?? {}
          onSearchChange({ sortBy: field ?? null, sortDirection: sort ?? null })
        }}
        paginationModel={{ page: page - 1, pageSize: size }}
        rowCount={total}
        rows={items}
        sortModel={sortBy ? [{ field: sortBy, sort: sortDirection }] : []}
      />
    </Box>
  )
}

export default PromotionTourList
