import { Grid, LinearProgress, Paper, Stack, Typography } from "@mui/material"
import { DataGrid, GridColDef } from "@mui/x-data-grid"
import { useQuery } from "@tanstack/react-query"
import { Flex, Span } from "components/common"
import { DateTime } from "luxon"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useToggle } from "react-use"
import { tourService } from "services"
import { formatDateTime, formatDateWithTimeZone } from "utils/common"

import {
  TourBookingCancelActionDialog,
  TourBookingDepositDialog,
  TourBookingEditDialog,
  TourBookingPaymentDialog,
  TourBookingViewActions,
  TourBookingViewCountdownTimer,
  TourIconWarningPayment,
  TourStatusChip,
} from "./components"

type TourBookingPassengerPriceInfo = {
  count: number
  label: string
  price: number
  total: number
}

const vehiclesName = {
  AIRPLANE: "Máy bay",
  CAR: "Xe ô tô",
  CUSTOM: "Tự chọn",
  HIGH_SPEED_TRAIN: "Tàu cao tốc",
  TRAIN: "Tàu hỏa",
}

const renderTableRows = (booking: TourBooking) => {
  const prices: TourBookingPassengerPriceInfo[] = [
    {
      count: booking.numberAdults,
      label: "Người lớn (Từ 11 tuổi trở lên)",
      price: booking.adultsPrice,
      total: booking.numberAdults * booking.adultsPrice,
    },
  ]
  if (booking.numberChildren1) {
    prices.push({
      count: booking.numberChildren1,
      label: "Trẻ em (ngủ ghép - Từ 2-5 tuổi)",
      price: booking.children1Price,
      total: booking.numberChildren1 * booking.children1Price,
    })
  }
  if (booking.numberChildren2) {
    prices.push({
      count: booking.numberChildren2,
      label: "Trẻ em (Từ 5-11 tuổi)",
      price: booking.children2Price,
      total: booking.numberChildren2 * booking.children2Price,
    })
  }
  if (booking.numberInfant) {
    prices.push({
      count: booking.numberInfant,
      label: "Em bé (Dưới 2 tuổi)",
      price: booking.infantPrice,
      total: booking.numberInfant * booking.infantPrice,
    })
  }
  return prices
}

const TourBookingListView = () => {
  const [isOpenBookingCancel, onToggleBookingCancel] = useToggle(false)
  const [isOpenEditBooking, onToggleEditBooking] = useToggle(false)
  const [isOpenPaymentBooking, onTogglePaymentBooking] = useToggle(false)
  const [isOpenDepositBooking, onToggleDepositBooking] = useToggle(false)
  const [isExpiredBooking, onToggleExpiredBooking] = useToggle(false)
  const [tourActionType, setTourActionType] = useState<"CANCEL" | "REFUND" | "REJECT">("CANCEL")
  const { code } = useParams()

  const {
    data: booking,
    isError,
    isLoading,
  } = useQuery({
    queryFn: () => tourService.getBooking({ id: Number(code) }),
    queryKey: ["tourService.getBooking", code],
  })

  useEffect(() => {
    if (!booking || isExpiredBooking) return
    const bookingExpired =
      ["DEPOSITED", "REQUEST_DEPOSIT", "REQUEST_PAYMENT", "RESERVED"].includes(booking.status) &&
      DateTime.fromISO(booking.bookingExpirationDate) <= DateTime.now()
    onToggleExpiredBooking(bookingExpired)
  }, [booking])

  if (isLoading)
    return (
      <Stack gap={2}>
        <LinearProgress />
        <Typography className="text-center" variant="h6">
          Đang tải dữ liệu Tour...
        </Typography>
      </Stack>
    )
  if (isError) return <Span>Lỗi tải dữ liệu tour</Span>
  if (!booking || Object.keys(booking).length === 0) {
    return <Span>Không có dữ liệu</Span>
  }
  const handleOpenCancelOrRefundBookingTour = (booking: TourBooking, action?: string) => {
    if (action === "REJECT") {
      setTourActionType("REJECT")
    } else if (["RESERVED"].includes(booking.status)) {
      setTourActionType("CANCEL")
    } else if (["CANCELLATION_REQUESTED", "DEPOSITED", "PAID"].includes(booking.status)) {
      setTourActionType("REFUND")
    }
    onToggleBookingCancel(true)
  }

  return (
    <Stack gap={3}>
      <Paper className="rounded p-3 shadow-md" variant="outlined">
        <Stack spacing={2}>
          <Typography className="font-semibold" variant="h5">
            Thông tin đặt chỗ
          </Typography>

          <Grid container spacing={1}>
            <Grid item md={6} xs={12}>
              <Span className="font-medium">Mã đặt chỗ: </Span>
              <Span className="text-blue-600 text-xl font-bold text-info">{booking.bookingCode}</Span>
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography>
                <Span className="font-medium">Trạng thái: </Span>
                <TourStatusChip status={booking.status} />
                <TourIconWarningPayment fontSize={36} startDate={booking.startDate} status={booking.status} />
              </Typography>
              {booking.requestPaymentAmount > 0 && (
                <>
                  <Span className="font-semibold text-error">Số tiền đã yêu cầu: </Span>
                  <Span className="text-xl font-bold text-error">{booking.requestPaymentAmount.toLocaleString()}</Span>
                </>
              )}
            </Grid>
            {["DEPOSITED", "REQUEST_DEPOSIT", "REQUEST_PAYMENT", "RESERVED"].includes(booking.status) && (
              <Grid item md={6} xs={12}>
                <Stack gap={1}>
                  <Flex gap={1}>
                    <Span className="font-medium">
                      Thời hạn {["DEPOSITED", "REQUEST_PAYMENT"].includes(booking.status) ? "thanh toán" : "giữ chỗ"}:{" "}
                    </Span>
                    <Span className="font-semibold">{formatDateWithTimeZone(booking.bookingExpirationDate)}</Span>
                  </Flex>
                  {!isExpiredBooking && (
                    <Flex>
                      <TourBookingViewCountdownTimer
                        bookingExpirationDate={booking.bookingExpirationDate}
                        onExpired={() => onToggleExpiredBooking(true)}
                      />
                    </Flex>
                  )}
                </Stack>
              </Grid>
            )}
            <Grid item md={6} xs={12}>
              <Span className="font-medium">Thời gian đặt tour: </Span>
              <Span className="font-semibold">{formatDateWithTimeZone(booking.createdAt)}</Span>
            </Grid>
          </Grid>
        </Stack>
      </Paper>

      <Paper className="rounded p-3 shadow-md" variant="outlined">
        <Stack spacing={2}>
          <Typography className="font-semibold" variant="h5">
            Thông tin liên hệ
          </Typography>

          <Grid container spacing={1}>
            <Grid item md={6} xs={12}>
              <Span className="font-medium">Họ tên khách hàng: </Span>
              <Span className="font-semibold">{booking.contactName}</Span>
            </Grid>
            <Grid item md={6} xs={12}>
              <Span className="font-medium">Điện thoại: </Span>
              <Span className="font-semibold">{booking.contactPhone}</Span>
            </Grid>
            <Grid item md={6} xs={12}>
              <Span className="font-medium">Email: </Span>
              <Span className="font-semibold">{booking.email}</Span>
            </Grid>
            <Grid item md={6} xs={12}>
              <Span className="font-medium">Địa chỉ: </Span>
              <Span className="font-semibold">{booking.address}</Span>
            </Grid>
          </Grid>
        </Stack>
      </Paper>

      <Paper className="rounded p-3 shadow-md" variant="outlined">
        <Stack spacing={2}>
          <Typography className="font-semibold" variant="h5">
            {booking.tour.tourName}
          </Typography>
          <Grid container spacing={1}>
            <Grid item md={6} xs={12}>
              <Span className="font-medium">Mã tour: </Span>
              <Span className="font-semibold">{booking.tour.tourCode}</Span>
            </Grid>
            <Grid item md={6} xs={12}>
              <Span className="font-medium">Thời gian: </Span>
              <Span className="font-semibold">
                {booking.tour.tourTotalDay}N{booking.tour.tourTotalNight}Đ
              </Span>
            </Grid>
            <Grid item md={6} xs={12}>
              <Span className="font-medium">Địa điểm khởi hành: </Span>
              <Span className="text-blue-600 font-semibold">{"TP Hồ Chí Minh"}</Span>
            </Grid>
            <Grid item md={6} xs={12}>
              <Span className="font-medium">Ngày khởi hành: </Span>
              <Span className="text-blue-600 font-semibold">{new Date(booking.startDate).toLocaleDateString()}</Span>
            </Grid>
            <Grid item md={6} xs={12}>
              <Span className="font-medium">Khách sạn: </Span>
              <Span className="text-blue-600 font-semibold">{booking.price.levelNam}</Span>
            </Grid>
            <Grid item md={6} xs={12}>
              <Span className="font-medium">Ngày về: </Span>
              <Span className="text-blue-600 font-semibold">{new Date(booking.endDate).toLocaleDateString()}</Span>
            </Grid>
            <Grid item md={6} xs={12}>
              <Span className="font-medium">Phương tiện: </Span>
              <Span className="font-semibold">
                {vehiclesName[booking.vehiclesGo]}
                {booking.vehiclesBack && booking.vehiclesBack !== booking.vehiclesGo
                  ? ` - ${vehiclesName[booking.vehiclesBack]}`
                  : ""}
              </Span>
            </Grid>
          </Grid>
        </Stack>
      </Paper>

      <Paper className="rounded p-3 shadow-md" variant="outlined">
        <Stack spacing={2}>
          <Typography className="font-semibold" variant="h5">
            Chi tiết đặt chỗ
          </Typography>

          <DataGrid
            columns={([] as GridColDef<TourBookingPassengerPriceInfo>[]).concat([
              {
                field: "label",
                headerName: "Loại hành khách",
                minWidth: 260,
                sortable: false,
              },
              {
                field: "price",
                flex: 1,
                headerName: "Giá tour",
                minWidth: 150,
                renderCell: ({ row: item }) => item.price.toLocaleString(),
                sortable: false,
              },
              {
                field: "count",
                flex: 1,
                headerName: "Số lượng",
                minWidth: 100,
                sortable: false,
              },
              {
                field: "total",
                headerName: "Thành tiền",
                minWidth: 180,
                renderCell: ({ row: item }) => <Span fontWeight="bold">{item.total.toLocaleString()}</Span>,
                sortable: false,
              },
            ])}
            getRowId={(row) => row.label}
            hideFooter
            paginationMode="client"
            rows={renderTableRows(booking)}
          />

          <Stack gap={1}>
            <Typography align="right">
              <Span className="font-semibold">Số phòng đơn phụ thu: </Span>

              <Span className="text-lg font-bold">
                {booking.totalSingleRoom
                  ? `${booking.totalSingleRoom} x ${booking.singleRoomPrice.toLocaleString()} = ${(
                      booking.totalSingleRoom * booking.singleRoomPrice
                    ).toLocaleString()}`
                  : 0}
              </Span>
            </Typography>
            <Typography align="right">
              <Span className="font-semibold">Tổng tiền: </Span>
              <Span className="text-lg font-bold">{booking.totalPrice.toLocaleString()}</Span>
            </Typography>
            {!booking.createdBy && (
              <Typography align="right">
                <Span className="font-semibold">Đã cọc / thanh toán: </Span>
                <Span className="text-lg font-bold text-success">
                  {(booking.totalAmountPaid + booking.totalPriceRefund).toLocaleString()}
                </Span>
              </Typography>
            )}
            <Typography align="right">
              <Span className="font-semibold">Hoa hồng F1 (HH trả sau): </Span>
              <Span className="text-lg font-bold text-info">{booking.totalCommission.toLocaleString() ?? 0}</Span>
            </Typography>
            <Typography align="right">
              <Span className="font-semibold">Hoa hồng F2 (HH trả trước): </Span>
              <Span className="text-lg font-bold text-info">
                {booking?.promotion?.totalAgencyPromotion?.toLocaleString() ?? 0}
              </Span>
            </Typography>
            <Typography align="right">
              <Span className="font-semibold">Phí nền tảng: </Span>
              <Span className="text-lg font-bold">{booking?.totalPlatformFee?.toLocaleString() ?? 0}</Span>
            </Typography>
            {!booking.createdBy && booking.totalPriceRefund > 0 && (
              <Typography align="right">
                <Span className="font-semibold">Đã hoàn: </Span>
                <Span className="text-lg font-bold text-warning">{booking.totalPriceRefund.toLocaleString()}</Span>
              </Typography>
            )}
          </Stack>
        </Stack>
      </Paper>

      <Paper className="rounded p-3 shadow-md" variant="outlined">
        <Stack spacing={2}>
          <Typography className="font-semibold" variant="h5">
            Lịch sử thao tác
          </Typography>

          <DataGrid
            columns={([] as GridColDef<AuditLog>[])
              .concat([
                {
                  field: "createdAt",
                  headerName: "Thời gian",
                  minWidth: 180,
                  renderCell: ({ row: item }) => formatDateTime(item.createdAt),
                  sortable: false,
                },
              ])
              .concat([
                {
                  field: "action",
                  flex: 1,
                  headerName: "Thao tác",
                  minWidth: 180,
                  sortable: false,
                },
                {
                  field: "newValue",
                  flex: 1,
                  headerName: "Chi tiết",
                  minWidth: 180,
                  renderCell: ({ row: item }) => <Span className="truncate">{item.newValue}</Span>,
                  sortable: false,
                },
                {
                  field: "modifiedBy",
                  headerName: "Người thực hiện",
                  minWidth: 300,
                  renderCell: ({ row: item }) => (
                    <Span>
                      {item.modifiedBy
                        ? item?.modifiedBy?.firstName && item?.modifiedBy?.lastName
                          ? `${item?.modifiedBy?.firstName} ${item?.modifiedBy?.lastName}`
                          : item?.modifiedBy?.username
                        : item?.bookerInfo}
                    </Span>
                  ),
                  sortable: false,
                },
              ])}
            hideFooter
            paginationMode="client"
            rows={booking.auditLogs}
          />
        </Stack>
      </Paper>

      <TourBookingViewActions
        booking={booking}
        onCancelOrRefund={() => handleOpenCancelOrRefundBookingTour(booking)}
        onDeposit={() => onToggleDepositBooking(true)}
        onEdit={() => onToggleEditBooking(true)}
        onPayment={() => onTogglePaymentBooking(true)}
        onReject={() => handleOpenCancelOrRefundBookingTour(booking, "REJECT")}
      />

      <TourBookingCancelActionDialog
        actionType={tourActionType}
        booking={booking}
        onClose={() => onToggleBookingCancel(false)}
        open={isOpenBookingCancel}
      />
      <TourBookingDepositDialog
        booking={booking}
        onClose={() => onToggleDepositBooking(false)}
        open={isOpenDepositBooking}
      />
      <TourBookingEditDialog
        booking={booking}
        isUpdateNote={false}
        onClose={() => onToggleEditBooking(false)}
        open={isOpenEditBooking}
      />
      <TourBookingPaymentDialog
        booking={booking}
        onClose={() => onTogglePaymentBooking(false)}
        open={isOpenPaymentBooking}
      />
    </Stack>
  )
}

export default TourBookingListView
